<template>
  <v-container>
    <v-row>
      <v-col lg="6" class="mx-auto">
        <v-card 
          elevation="4" 
          class="mx-auto pt-4" 
          align="center"
          justify="center">
          <v-img 
            src="@/assets/tf-portrait.jpg"
            class="rounded-circle"
            max-width="50%"
          ></v-img>

          <v-card-title class="justify-center text-md-h2 text-h4">
            Dr. Tom Feltwell
          </v-card-title>

          <v-card-text class="text-md-h5 text-h6 text-center">
              <div>Full Stack Software Engineer</div>
          </v-card-text>

          <v-card-text class="subtitle-2 text-center">
            <v-icon>
              mdi-garage-variant
            </v-icon>
            Fullstack Software Engineer @ <a href="https://sapi.com">SAPI</a>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-text>
            <v-row>
              <v-col>
                Hi, I'm Tom, I'm a full stack software engineer working in Fintech, currently building liquidity-as-a-service tech at 
                <a href="https://sapi.com">SAPI</a>. I have 8 years of software engineering experience across the stack, working across industry,
                charity and academic sectors. I am predominantly Typescript/Javascript focused, but have experience in Python and other languages.
                I have a diverse technical background across the stack with a variety of technologies, often focused on using the right tool to solve
                the problem. I have a PhD in human-computer interaction and strive to be a product engineer every day.
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                I have a variety of projects under my belt which can be see on the Portfolio page. Don't hesitate to get in touch if you'd like a chat! 
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-actions class="mx-auto">
            <v-btn to="/portfolio">Portfolio</v-btn>
            <v-btn to="/contact">Contact</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
