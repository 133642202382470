<template>
  <v-app>
    <v-app-bar
      app
      color="teal lighten-2"
      hide-on-scroll
    >
      <v-toolbar-title class="overline" shrink-on-scroll>
        Dr. Tom Feltwell
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn class="mx-2" to="/">
          About
        </v-btn>
        <v-btn class="mx-2" to="/portfolio">
          Portfolio
        </v-btn>
        <!-- <v-btn class="mx-2" to="/research">
          Research
        </v-btn> -->
        <v-btn class="mx-2" to="/contact">
          Contact
        </v-btn>
      <!-- <div class="d-flex align-center">
        
      </div> -->

      <!-- <v-spacer></v-spacer> -->

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    // HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>
