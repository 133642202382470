export default {
  getPortfolioTitles(state) {
    return state.portfolio.map( item => item.title)
  },
  getPortfolioOverview(state) {
    return state.portfolio
  },
  getPortfolioItem: (state) => (name) => {
    return state.portfolio.find( item => item.name === name)
  }
}